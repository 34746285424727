import { BlockStack, Icon, Text } from '@shopify/polaris'
import { AlertTriangleIcon } from '@shopify/polaris-icons'

export default function Custom404() {
  return <>
    <BlockStack>
      <span className="icon-lg">
        <Icon source={AlertTriangleIcon} tone="base" />
      </span>
      <Text variant="headingMd" as="h1" alignment="center">
        There is NO page with this URL
      </Text>
      <Text variant="bodyMd" as="p" alignment="center">
        Check the URL and try again, or use the search bar to find what you need.
      </Text>
    </BlockStack>
  </>;
}
